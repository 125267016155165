@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Qochy";
  src: url("fonts/Qochy.otf");
}
@font-face {
  font-family: "Optima";
  src: url("fonts/optima.ttf");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* for animations */
.hidden-from-bottom {
  transform: translateY(100%);
  opacity: 0;
}

.visible-from-bottom {
  transform: translateY(0);
  opacity: 1;
  transition: all 0.5s ease-out;
}
